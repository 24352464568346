import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex items-center justify-between gap-x-4 mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_ActivityTable = _resolveComponent("ActivityTable")!
  const _component_ActivityModal = _resolveComponent("ActivityModal")!
  const _component_ActivityAddObjectModal = _resolveComponent("ActivityAddObjectModal")!
  const _component_ViewLayout = _resolveComponent("ViewLayout")!

  return (_openBlock(), _createBlock(_component_ViewLayout, null, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_LoadingSpinner, { class: "h-screen" }, null, 512), [
        [_vShow, _ctx.isLoaded]
      ]),
      (!_ctx.isLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Filter, {
              class: "mb-5",
              searchRequestOne: _ctx.livingHouses,
              "onUpdate:searchRequestOne": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.livingHouses) = $event)),
              searchRequestTwo: _ctx.livingObjects,
              "onUpdate:searchRequestTwo": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.livingObjects) = $event)),
              onOnFilter: _ctx.handleFilter
            }, null, 8, ["searchRequestOne", "searchRequestTwo", "onOnFilter"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_UiButton, {
                class: "rounded-sm bg-blue text-white p-2.5",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isActivityAddObjectModalVisible = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_UiIcon, {
                    name: "plus",
                    class: "mr-2.5"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('ACTIVITY_ADD_OBJECT_BUTTON')), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_ActivityTable, {
              headerClient: _ctx.headerClient,
              "onUpdate:headerClient": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.headerClient) = $event)),
              headerResidence: _ctx.headerResidence,
              "onUpdate:headerResidence": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.headerResidence) = $event)),
              headers: _ctx.activityTableHeaders,
              loading: false,
              filters: _ctx.tableFilters,
              tableInfo: _ctx.activityList,
              numberOfVisibleRows: _ctx.perPage,
              "onUpdate:numberOfVisibleRows": _cache[5] || (_cache[5] = ($event: any) => (_ctx.perPage = $event)),
              onOnResidenceModification: _ctx.onResidenceModification,
              onShowActivityModal: _ctx.modifyResidence
            }, null, 8, ["headerClient", "headerResidence", "headers", "filters", "tableInfo", "numberOfVisibleRows", "onOnResidenceModification", "onShowActivityModal"]),
            _createVNode(_component_ActivityModal, {
              "model-value": _ctx.isActivityModalVisible,
              residenceToModify: _ctx.residenceToModify,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.isActivityModalVisible = $event)),
              onConfirmResidenceModifications: _ctx.onResidenceModification,
              onConfirmDeleteResidence: _ctx.onResidenceDelete
            }, null, 8, ["model-value", "residenceToModify", "onConfirmResidenceModifications", "onConfirmDeleteResidence"]),
            _createVNode(_component_ActivityAddObjectModal, {
              "model-value": _ctx.isActivityAddObjectModalVisible,
              clientsInfo: _ctx.clientsInfo,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.isActivityAddObjectModalVisible = $event)),
              onConfirmAddNewResidence: _ctx.onAddNewResidence
            }, null, 8, ["model-value", "clientsInfo", "onConfirmAddNewResidence"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}