import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded-lg bg-white p-2.5" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "mr-10 flex items-center" }
const _hoisted_4 = { class: "text-sm font-medium" }
const _hoisted_5 = { class: "flex max-w-screen-lg w-full" }
const _hoisted_6 = {
  key: 0,
  class: "pt-5 flex justify-between gap-x-2.5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_UiNativeSelect = _resolveComponent("UiNativeSelect")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_FilterCounter = _resolveComponent("FilterCounter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_UiIcon, {
          class: "mr-2",
          name: "filter"
        }),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('FILTER_TITLE')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_UiNativeSelect, {
          "model-value": _ctx.searchRequestOne,
          class: "flex-1 mr-2.5",
          options: ['houses-1', 'houses-2', 'houses-3'],
          placeholder: _ctx.$t('FILTER_INPUT_PLACEHOLDER_CLIENTS'),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:searchRequestOne', $event)))
        }, null, 8, ["model-value", "placeholder"]),
        _createVNode(_component_UiNativeSelect, {
          "model-value": _ctx.searchRequestTwo,
          class: "flex-1 mr-2.5",
          options: ['objects-1', 'objects-2', 'objects-3'],
          placeholder: _ctx.$t('FILTER_INPUT_PLACEHOLDER_RESIDENCE'),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:searchRequestTwo', $event)))
        }, null, 8, ["model-value", "placeholder"]),
        _createVNode(_component_UiButton, {
          class: "px-4 bg-mediumGrey text-base text-white hover:bg-darkGrey",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onFilter')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('FILTER_INPUT_BUTTON')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    (_ctx.filterCounterInfo.interventions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_FilterCounter, {
            counterText: _ctx.$t('FILTER_COUNTER_NUMBER_OF_INTERVENTIONS'),
            counterNumber: _ctx.filterCounterInfo.interventions,
            "counter-color": 'bg-green'
          }, null, 8, ["counterText", "counterNumber"]),
          _createVNode(_component_FilterCounter, {
            counterText: _ctx.$t('FILTER_COUNTER_NUMBER_OF_AWAITING_INTERVENTIONS'),
            counterNumber: _ctx.filterCounterInfo.awaitingInterventions,
            "counter-color": 'bg-orange'
          }, null, 8, ["counterText", "counterNumber"]),
          _createVNode(_component_FilterCounter, {
            counterText: _ctx.$t('FILTER_COUNTER_AVERAGE_TIME'),
            counterNumber: _ctx.filterCounterInfo.time,
            "counter-color": 'bg-blue'
          }, null, 8, ["counterText", "counterNumber"])
        ]))
      : _createCommentVNode("", true)
  ]))
}