
import { defineComponent, ref, computed } from 'vue'
import { useCancelToken } from '@/hooks/useCancelToken'
import useStore from '@/store'
import { DEVICE_ACTION_TYPES } from '@/store/device/actions'
import LoadingSpinner from '@/components/particles/common/LoadingSpinner.vue'
import ViewLayout from '@/components/particles/common/ViewLayout.vue'
import ActivityTable from '@/components/particles/ActivityView/ActivityTable.vue'
import { activityTableHeaders } from '@/helpers/tableHeaders'
import Filter from '@/components/particles/ActivityView/Filter.vue'
import ActivityModal from '@/components/particles/ActivityView/ActivityModal.vue'
import ActivityAddObjectModal from '@/components/particles/ActivityView/ActivityAddObjectModal.vue'

export default defineComponent({
  name: 'ActivityView',
  components: {
    Filter,
    LoadingSpinner,
    ViewLayout,
    ActivityTable,
    ActivityModal,
    ActivityAddObjectModal,
  },
  setup() {
    const store = useStore()
    const getActivityCancelToken = useCancelToken()
    const getClientsInfoCancelToken = useCancelToken()
    const headerClient = ref('')
    const headerResidence = ref()
    const filterString = ref('')
    const perPage = ref()
    const isActivityModalVisible = ref(false)
    const residenceToModify = ref({})
    const isActivityAddObjectModalVisible = ref(false)



    const getActivityInfo = async () => {
      try {
        await Promise.all([
          store.dispatch(
            DEVICE_ACTION_TYPES.GET_DEVICE_INFO,
            getActivityCancelToken.value
          ),
          store.dispatch(
            DEVICE_ACTION_TYPES.GET_CLIENTS_INFO,
            getClientsInfoCancelToken.value
          ),
        ])
      } catch (error) {
        console.log('error: ', error)
      }
    }
    getActivityInfo()

    const tableFilters = computed(() => [
      headerClient.value,
      headerResidence.value,
      filterString.value,
    ])

    const activityList = computed(() => store.state.activity.activityInfo)
    const clientsInfo = computed(() => store.state.activity.clientsInfo)
    const isLoaded = computed(() => store.state.activity.isLoaded)
    

    const modifyResidence = (residence: any) => {
      residenceToModify.value = { ...residence }
      isActivityModalVisible.value = true
    }

    const onResidenceModification = async (modifiedResidence: any) => {
      isActivityModalVisible.value = false
      await store.dispatch(
        DEVICE_ACTION_TYPES.UPDATE_RESIDENCE,
        modifiedResidence
      )
      getActivityInfo()
    }

    const onResidenceDelete = async (id: number) => {
      isActivityModalVisible.value = false
      await store.dispatch(DEVICE_ACTION_TYPES.DELETE_RESIDENCE, id)
      getActivityInfo()
    }

    const onAddNewResidence = async (residence: any) => {
      isActivityAddObjectModalVisible.value = false

      //TODO: Change url in ADD_NEW_RESIDENCE action when the endpoint will be created on backend side

      // await store.dispatch(DEVICE_ACTION_TYPES.ADD_NEW_RESIDENCE, residence)
      // getActivityInfo()
    }
    //filter
    const livingHouses = ref()
    const livingObjects = ref()

    const handleFilter = () => {
      //TODO: Filter by livingHouses.value and/or livingObjects.value
    }


    return {
      activityList,
      isLoaded,
      perPage,
      tableFilters,
      headerClient,
      headerResidence,
      filterString,
      activityTableHeaders,
      isActivityModalVisible,
      residenceToModify,
      modifyResidence,
      onResidenceModification,
      onResidenceDelete,
      isActivityAddObjectModalVisible,
      clientsInfo,
      onAddNewResidence,
      livingHouses,
      livingObjects,
      handleFilter
    }
  },
})
