
import { defineComponent, computed, PropType } from 'vue'
import { activityTableHeaders } from '@/helpers/tableHeaders'
import ActivityCheckbox from '@/components/particles/ActivityView/ActivityCheckbox.vue'
import { INTERVENTION_DELAY_HOURS } from '@/constants'
import { hoursToMinutes, minutesToHours } from '@/helpers/utils'
import { useI18n } from 'vue-i18n'
import { IDevice } from '@/store/device/types'

export default defineComponent({
  components: { ActivityCheckbox },
  props: {
    residenceToModify: {
      type: Object as PropType<IDevice>,
      default: () => ({}),
    },
  },
  emits: [
    'update:model-value',
    'confirmResidenceModifications',
    'confirmDeleteResidence',
  ],
  setup(props) {
    const { t } = useI18n()
    const objectToModify = computed(() => ({ ...props.residenceToModify }))

    //Need to have readable options in select
    const interventionDelayHoursOptions = INTERVENTION_DELAY_HOURS.map(
      (item) => {
        if (item === 0) {
          return t('ACTIVITY_TABLE_IMMEDIATELY')
        } else {
          return item + 'h'
        }
      }
    )

    const checkedValue = (parameter: boolean): boolean => {
      return parameter === true ? true : false
    }

    //Need to send null in intervention_delay field if os required = trues
    const onServiceOrderNumberChangeToYes = () => {
      objectToModify.value.parameters.is_service_order_number_required = true
      objectToModify.value.parameters.intervention_delay = null
    }
    const onServiceOrderNumberChangeToNo = () => {
      objectToModify.value.parameters.is_service_order_number_required = false
      objectToModify.value.parameters.intervention_delay = hoursToMinutes(
        t('ACTIVITY_TABLE_IMMEDIATELY')
      )
    }

    return {
      activityTableHeaders,
      objectToModify,
      checkedValue,
      interventionDelayHoursOptions,
      onServiceOrderNumberChangeToYes,
      hoursToMinutes,
      minutesToHours,
      onServiceOrderNumberChangeToNo,
    }
  },
})
