
import { defineComponent, ref, PropType } from 'vue'
import { IDevice } from '@/store/device/types'
import { minutesToHours } from '@/helpers/utils'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    tableInfo: {
      type: Array as PropType<IDevice[]>,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    headerClient: {
      type: String,
      required: true,
      default: '',
    },
    headerResidence: {
      type: String,
      required: true,
      default: '',
    },
    numberOfVisibleRows: {
      type: Number,
      default: 10,
    },
  },
  emits: [
    'update:headerClient',
    'update:headerResidence',
    'update:numberOfVisibleRows',
    'showActivityModal',
  ],

  setup(props) {
    const { t } = useI18n()
    console.log(props.tableInfo)
    //Leave only types of objects in array => delete duplicate values
    const filteredSelectOptionClient = () => {
      return [
        ...new Set(
          props.tableInfo.map((value: IDevice) => value.residence.client?.name)
        ),
      ]
    }
    const filteredSelectOptionResidence = () => {
      return [
        ...new Set(
          props.tableInfo.map(
            (value: IDevice) => value.residence.parameters.name
          )
        ),
      ]
    }

    const parameterValue = (parameter: boolean | null) => {
      if (parameter === null) return
      return parameter === true
        ? t('ACTIVITY_TABLE_YES')
        : t('ACTIVITY_TABLE_NO')
    }
    const parameterValueInterventionDelay = (parameter: number) => {
      if (parameter === null) return
      return parameter === 0
        ? t('ACTIVITY_TABLE_IMMEDIATELY')
        : minutesToHours(parameter)
    }

    return {
      filteredSelectOptionClient,
      filteredSelectOptionResidence,
      minutesToHours,
      parameterValue,
      parameterValueInterventionDelay,
    }
  },
})
