import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-8 bg-lightGrey" }
const _hoisted_2 = { class: "text-black text-sm font-medium" }
const _hoisted_3 = { class: "mb-7 font-medium text-blue" }
const _hoisted_4 = { class: "flex gap-x-4 mb-4" }
const _hoisted_5 = { class: "flex gap-x-4 mb-4" }
const _hoisted_6 = { class: "flex gap-x-4 mb-7" }
const _hoisted_7 = { class: "mb-4 font-medium text-blue" }
const _hoisted_8 = { class: "mb-4 flex rounded-sm overflow-hidden" }
const _hoisted_9 = { class: "flex items-center justify-center max-w-[50px] w-full bg-blue" }
const _hoisted_10 = { class: "w-full p-5 bg-lightGrey" }
const _hoisted_11 = { class: "mb-7 font-medium text-blue" }
const _hoisted_12 = { class: "text-black text-base" }
const _hoisted_13 = { class: "flex space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiNativeSelect = _resolveComponent("UiNativeSelect")!
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_UiInputText = _resolveComponent("UiInputText")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiModal = _resolveComponent("UiModal")!

  return (_openBlock(), _createBlock(_component_UiModal, { "wrapper-class": "max-w-2xl w-full rounded-lg overflow-hidden" }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_HEADER')), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
        class: "p-5"
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_MODAL_TITLE_GENERAL_INFORMATION')), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_UiNativeSelect, {
            "model-value": _ctx.newResidence.client.value,
            label: _ctx.newResidence.client.label,
            required: true,
            placeholder: _ctx.$t('ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_CLIENT'),
            errorMessage: _ctx.newResidence.client.errorMessage.value,
            class: "flex-1",
            options: _ctx.clients,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleUpdate('client', $event)))
          }, null, 8, ["model-value", "label", "placeholder", "errorMessage", "options"]),
          _createVNode(_component_UiNativeSelect, {
            "model-value": _ctx.newResidence.residence.value,
            label: _ctx.newResidence.residence.label,
            errorMessage: _ctx.newResidence.residence.errorMessage.value,
            required: true,
            placeholder: _ctx.$t('ACTIVITY_TABLE_HEADER_RESIDENCE'),
            class: "flex-1",
            options: _ctx.residences,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleUpdate('residence', $event)))
          }, null, 8, ["model-value", "label", "errorMessage", "placeholder", "options"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_UiNativeSelect, {
            "model-value": _ctx.newResidence.client.value,
            label: _ctx.newResidence.build.label,
            required: true,
            placeholder: _ctx.$t('ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_BUILD'),
            errorMessage: _ctx.newResidence.client.errorMessage.value,
            class: "flex-1",
            options: _ctx.builds,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleUpdate('build', $event)))
          }, null, 8, ["model-value", "label", "placeholder", "errorMessage", "options"]),
          _createVNode(_component_UiNativeSelect, {
            "model-value": _ctx.newResidence.residence.value,
            label: _ctx.newResidence.staircase.label,
            errorMessage: _ctx.newResidence.residence.errorMessage.value,
            required: true,
            placeholder: _ctx.$t('ACTIVITY_TABLE_HEADER_STAIRCASE'),
            class: "flex-1",
            options: _ctx.staircases,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleUpdate('staircase', $event)))
          }, null, 8, ["model-value", "label", "errorMessage", "placeholder", "options"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_UiNativeSelect, {
            "model-value": _ctx.newResidence.object.value,
            label: _ctx.newResidence.object.label,
            required: true,
            placeholder: _ctx.$t('ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_OBJECT'),
            errorMessage: _ctx.newResidence.object.errorMessage.value,
            class: "flex-1",
            options: ['skydome', 'rat_trap', 'vmc', 'lift_pump'],
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleUpdate('object', $event)))
          }, null, 8, ["model-value", "label", "placeholder", "errorMessage"]),
          _createVNode(_component_UiNativeSelect, {
            "model-value": _ctx.newResidence.model.value,
            label: _ctx.newResidence.model.label,
            errorMessage: _ctx.newResidence.model.errorMessage.value,
            required: true,
            placeholder: _ctx.$t('ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_MODEL'),
            class: "flex-1",
            options: ['model', 'mark'],
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleUpdate('model', $event)))
          }, null, 8, ["model-value", "label", "errorMessage", "placeholder"])
        ]),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_MODAL_TITLE_CONFIGURATION')), 1),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_UiIcon, { name: "plus" })
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_MODAL_HELPER_TITLE')), 1),
            _createElementVNode("ul", _hoisted_12, [
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_MODAL_HELPER_STEP_1')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_MODAL_HELPER_STEP_2')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_MODAL_HELPER_STEP_3')), 1)
            ])
          ])
        ]),
        _createVNode(_component_UiInputText, {
          modelValue: _ctx.newResidence.id.value.value,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newResidence.id.value.value) = $event)),
          errorMessage: _ctx.newResidence.id.errorMessage.value,
          required: true,
          label: _ctx.newResidence.id.label,
          type: "text",
          class: "mb-5 flex-1"
        }, null, 8, ["modelValue", "errorMessage", "label"]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_UiButton, {
            class: "py-3 bg-lightGrey text-darkGrey w-full text-sm hover:text-red",
            type: "button",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update:model-value', false)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('DASHBOARD_TABLE_POP_UP_ADD_OS_CANCEL')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_UiButton, {
            class: "w-full py-3 bg-blue hover:bg-darkBlue text-white",
            type: "submit",
            disabled: !_ctx.isValid,
            loading: _ctx.isSubmitting
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('DASHBOARD_TABLE_POP_UP_ADD_OS_CONFIRM')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading"])
        ])
      ], 32)
    ]),
    _: 1
  }))
}