
import { defineComponent, PropType, reactive, computed } from 'vue'
import {
  useField,
  useForm,
  useIsFormValid,
  useIsSubmitting,
} from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { IClient } from '@/store/device/types'

export default defineComponent({
  props: {
    clientsInfo: {
      type: Array as PropType<IClient[]>,
      default: () => [],
    },
  },
  emits: ['update:model-value', 'confirmAddNewResidence'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const { handleSubmit, setFieldValue } = useForm({
      validationSchema: {
        object: 'required',
        residence: 'required',
        client: 'required',
        model: 'required',
        id: 'required|numeric',
      },
    })

    const newResidence = reactive({
      client: useField('client', '', {
        label: t('ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_CLIENT'),
      }),
      residence: useField('residence', '', {
        label: t('ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_RESIDENCE'),
      }),
      object: useField('object', '', {
        label: t('ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_OBJECT'),
      }),
      model: useField('model', '', {
        label: t('ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_MODEL'),
      }),
      id: useField('id', '', {
        label: t('ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_ID'),
      }),
    })

    const clients = computed(() =>
      props.clientsInfo.map((client) => client.name)
    )

    const residences = computed(() => {
      return props.clientsInfo
        .map((client) =>
          client.spaces.map((space) => {
            return space.parameters.name
          })
        )
        .flat()
    })

    const handleUpdate = (filedName: string, event: string) => {
      setFieldValue(filedName, event)
    }

    const onSubmit = handleSubmit(
      ({ object, model, client, residence, id }) => {
        emit('confirmAddNewResidence', { object, model, client, residence, id })
      }
    )

    return {
      onSubmit,
      isValid: useIsFormValid(),
      isSubmitting: useIsSubmitting(),
      newResidence,
      clients,
      residences,
      handleUpdate,
    }
  },
})
