
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  props: {
    checked: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['update:modelValue', 'change'],
  setup(props) {
    const { handleChange } = useField(props.name, undefined, {
      checkedValue: props.value,
    })
    return {
      handleChange,
    }
  },
})
