import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "py-8 bg-lightGrey" }
const _hoisted_2 = { class: "text-black text-sm font-medium" }
const _hoisted_3 = { class: "p-5" }
const _hoisted_4 = { class: "mb-2.5 flex items-center justify-between" }
const _hoisted_5 = { class: "text-base-title font-medium" }
const _hoisted_6 = { class: "mb-2.5 p-2.5 min-h-[64px] flex items-center justify-between bg-lightGrey rounded-sm" }
const _hoisted_7 = { class: "text-base font-medium" }
const _hoisted_8 = { class: "flex justify-between gap-x-1 max-w-[108px] w-full" }
const _hoisted_9 = { class: "mb-2.5 p-2.5 min-h-[64px] flex items-center justify-between bg-lightGrey rounded-sm" }
const _hoisted_10 = { class: "text-base font-medium" }
const _hoisted_11 = { class: "flex justify-between gap-x-1 max-w-[108px] w-full" }
const _hoisted_12 = { class: "mb-2.5 p-2.5 min-h-[64px] flex items-center justify-between bg-lightGrey rounded-sm" }
const _hoisted_13 = { class: "text-base font-medium" }
const _hoisted_14 = { class: "max-w-[108px] w-full text-right" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "mb-5 p-2.5 min-h-[64px] flex items-center justify-between bg-lightGrey rounded-sm" }
const _hoisted_17 = { class: "text-base font-medium" }
const _hoisted_18 = { class: "flex justify-between gap-x-1 max-w-[108px] w-full" }
const _hoisted_19 = { class: "flex space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_ActivityCheckbox = _resolveComponent("ActivityCheckbox")!
  const _component_UiNativeSelect = _resolveComponent("UiNativeSelect")!
  const _component_UiModal = _resolveComponent("UiModal")!

  return (_openBlock(), _createBlock(_component_UiModal, { "wrapper-class": "max-w-2xl w-full rounded-lg overflow-hidden" }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_MODAL_HEADER')) + " " + _toDisplayString(_ctx.objectToModify.residence.parameters.name), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.objectToModify.type), 1),
          _createVNode(_component_UiButton, {
            class: "px-2.5 py-2 text-red rounded-sm border-red border-2 hover:text-white hover:bg-red",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirmDeleteResidence', _ctx.objectToModify.id)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_UiIcon, {
                class: "mr-2.5 bg-white rounded-2xs",
                name: "basket"
              }),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_MODAL_DELETE_OBJECT_BUTTON')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_HEADER_LOCALLY_VERIFIED')), 1),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_ActivityCheckbox, {
              label: _ctx.$t('ACTIVITY_TABLE_YES'),
              name: _ctx.$t('ACTIVITY_TABLE_HEADER_LOCALLY_VERIFIED'),
              checked: 
              _ctx.checkedValue(_ctx.objectToModify.parameters.is_inspection_required)
            ,
              disabled: _ctx.objectToModify.parameters.is_contracted,
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.objectToModify.parameters.is_inspection_required = true))
            }, null, 8, ["label", "name", "checked", "disabled"]),
            _createVNode(_component_ActivityCheckbox, {
              label: _ctx.$t('ACTIVITY_TABLE_NO'),
              name: _ctx.$t('ACTIVITY_TABLE_HEADER_LOCALLY_VERIFIED'),
              checked: 
              !_ctx.checkedValue(_ctx.objectToModify.parameters.is_inspection_required)
            ,
              disabled: _ctx.objectToModify.parameters.is_contracted,
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.objectToModify.parameters.is_inspection_required = false))
            }, null, 8, ["label", "name", "checked", "disabled"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_HEADER_OS_NUMBER_OBLIGATORY')), 1),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_ActivityCheckbox, {
              label: _ctx.$t('ACTIVITY_TABLE_YES'),
              name: _ctx.$t('ACTIVITY_TABLE_HEADER_OS_NUMBER_OBLIGATORY'),
              checked: 
              _ctx.checkedValue(
                _ctx.objectToModify.parameters.is_service_order_number_required
              )
            ,
              disabled: _ctx.objectToModify.parameters.is_contracted,
              onChange: _ctx.onServiceOrderNumberChangeToYes
            }, null, 8, ["label", "name", "checked", "disabled", "onChange"]),
            _createVNode(_component_ActivityCheckbox, {
              label: _ctx.$t('ACTIVITY_TABLE_NO'),
              name: _ctx.$t('ACTIVITY_TABLE_HEADER_OS_NUMBER_OBLIGATORY'),
              checked: 
              !_ctx.checkedValue(
                _ctx.objectToModify.parameters.is_service_order_number_required
              )
            ,
              disabled: _ctx.objectToModify.parameters.is_contracted,
              onChange: _ctx.onServiceOrderNumberChangeToNo
            }, null, 8, ["label", "name", "checked", "disabled", "onChange"])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_HEADER_TIME_BEFORE_INTERVENTION')), 1),
          _createElementVNode("div", _hoisted_14, [
            (_ctx.objectToModify.parameters.intervention_delay === null)
              ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_NO_DATA')), 1))
              : (_openBlock(), _createBlock(_component_UiNativeSelect, {
                  key: 1,
                  "model-value": 
              _ctx.minutesToHours(_ctx.objectToModify.parameters.intervention_delay)
            ,
                  disabled: 
              _ctx.objectToModify.parameters.is_contracted ||
              _ctx.objectToModify.parameters.is_service_order_number_required
            ,
                  class: "flex-1",
                  options: _ctx.interventionDelayHoursOptions,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (
              _ctx.objectToModify.parameters.intervention_delay =
                _ctx.hoursToMinutes($event)
            ))
                }, null, 8, ["model-value", "disabled", "options"]))
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('ACTIVITY_TABLE_HEADER_INFO_FOR_MANAGER')), 1),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_ActivityCheckbox, {
              label: _ctx.$t('ACTIVITY_TABLE_YES'),
              name: _ctx.$t('ACTIVITY_TABLE_HEADER_INFO_FOR_MANAGER'),
              checked: 
              _ctx.checkedValue(_ctx.objectToModify.parameters.is_transferable_to_manager)
            ,
              onChange: _cache[4] || (_cache[4] = ($event: any) => (
              _ctx.objectToModify.parameters.is_transferable_to_manager = true
            ))
            }, null, 8, ["label", "name", "checked"]),
            _createVNode(_component_ActivityCheckbox, {
              label: _ctx.$t('ACTIVITY_TABLE_NO'),
              name: _ctx.$t('ACTIVITY_TABLE_HEADER_INFO_FOR_MANAGER'),
              checked: 
              !_ctx.checkedValue(
                _ctx.objectToModify.parameters.is_transferable_to_manager
              )
            ,
              onChange: _cache[5] || (_cache[5] = ($event: any) => (
              _ctx.objectToModify.parameters.is_transferable_to_manager = false
            ))
            }, null, 8, ["label", "name", "checked"])
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_UiButton, {
            class: "py-3 bg-lightGrey text-darkGrey w-full text-sm hover:text-red",
            type: "button",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:model-value', false)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('DASHBOARD_TABLE_POP_UP_ADD_OS_CANCEL')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_UiButton, {
            class: "w-full py-3 bg-blue hover:bg-darkBlue text-white",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('confirmResidenceModifications', _ctx.objectToModify)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('DASHBOARD_TABLE_POP_UP_ADD_OS_CONFIRM')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}